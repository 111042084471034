import { IRootState } from '@/modules/common/common.interface';
import { GetterTree } from 'vuex';
import { IAccountState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<IAccountState, IRootState> = {
  form_allowance_remaining(state: IAccountState): number | null {
    const remaining: number | null = state.account_allowance?.forms?.remaining;
    return remaining;
  },
  short_url_allowance_remaining(state: IAccountState): number | null {
    const allowance: number | null = state.account_allowance?.shortUrls?.remaining;
    return allowance;
  },
  page_allowance_remaining(state: IAccountState): number | null {
    const remaining: number | null = state.account_allowance?.pages?.remaining;
    return remaining;
  }
};
